import { isMobile } from '@darkobits/tsx/lib/runtime'
import { loadAll } from '@tsparticles/all'
import Particles, { initParticlesEngine } from '@tsparticles/react'
import pDebounce from 'p-debounce'
import React from 'react'
import { twMerge } from 'tailwind-merge'
import { useAsyncEffect } from 'use-async-effect'

import SmartSuspense from 'components/SmartSuspense'
import getParticlesConfig from 'etc/particles-config'

import classes from './Particles.css'

/**
 * Lazy-load this so that we don't block rendering of other content on slow
 * clients.
 */
// const Particles = React.lazy(async () => import('@tsparticles/react'));

export default function ParticlesComponent() {
  const [initialized, setInitialized] = React.useState(false)
  const [opacity, setOpacity] = React.useState(0)
  const particlesConfig = React.useMemo(() => getParticlesConfig(), [])

  useAsyncEffect(async isMounted => {
    await initParticlesEngine(loadAll)
    if (!isMounted()) return
    setInitialized(true)
  }, [])

  /**
   * Reveals particles after they have initialized. This function is debounced
   * because ts-particles will call this init function multiple times in rapid
   * succession, and we only want to call it once.
   */
  const onFinishedLoading = React.useCallback(pDebounce(() => {
    if (isMobile()) {
      setOpacity(0.72)
    } else {
      setOpacity(1)
    }

    return Promise.resolve()
  }, 1000), [])

  if (initialized) return (
    <SmartSuspense>
      <div
        aria-hidden="true"
        className={twMerge('h-full', classes.particles)}
        style={{ opacity }}
      >
        <Particles
          className="h-full"
          particlesLoaded={onFinishedLoading}
          options={particlesConfig}
        />
      </div>
    </SmartSuspense>
  )
}