import { isStandalone } from '@darkobits/react-kit/platform'
import * as R from 'ramda'
import React from 'react'
import { twMerge } from 'tailwind-merge'

import log from 'lib/log'

// const debugBackgroundImage = `
//   radial-gradient(circle, black 0%, rgba(255, 0, 0, 0.2) 50%, black 100%)
// `;

/**
 * @private
 *
 * Computes the dimensions for the portal (which should never change) as well
 * as the top and left offsets needed to keep the portal centered in the
 * viewport.
 *
 * Notes:
 *
 * TL;DR iPads get screen dimensions mixed up so we have to do some gymnastics
 * to correct for it.
 *
 * We need to compute the "true" width and height of the screen because iPads
 * report their width and height as if the device was in landscape orientation
 * whereas iPhones report their width and height assuming a portrait
 * orientation.
 *
 * This is problematic because if an iPad is in landscape orientation, we need
 * to compute its left offset using window.innerWidth and screen.height (re: the
 * larger of the two dimensions). The same idiosyncrasy applies to computing
 * top offsets and initial width and height.
 *
 * It is also noteworthy to mention why we're not using the much simpler
 * `screen.orientation.type` property to determine if we're in landscape or
 * portrait orientation. This property does not seem to get updated until after
 * the `orientationchange` and/or `resize` events have fired, so if we rely on
 * it we will be using a stale value. Using `matchMedia` is likely far more
 * expensive, but it is accurate.
 *
 * Addenda:
 *
 * - Safari will report _the same value_ for window.innerWidth and
 *   window.innerHeight, but this only seems to happen in PWA mode.
 * - That's fine because in PWA/standalone mode, we need to use the outer width
 *   and height anyway. One would think these two things would be the same since
 *   there is no browser chrome in the way to make inner dimensions any less
 *   than outer dimensions, but no.
 */
function computePortalDimensions() {
  const isLandscape = window.matchMedia('(orientation: landscape)').matches

  if (import.meta.env.DEV && isLandscape && !screen.orientation.type.includes('landscape')) {
    log.warn('Safari is (still) mis-reporting screen.orientation.type.')
  }

  const screenWidth = isLandscape
    ? R.max(screen.width, screen.height)
    : R.min(screen.width, screen.height)

  const screenHeight = isLandscape
    ? R.min(screen.width, screen.height)
    : R.max(screen.width, screen.height)

  const innerWidth = isLandscape
    ? R.max(window.innerWidth, window.innerHeight)
    : R.min(window.innerWidth, window.innerHeight)

  const innerHeight = isLandscape
    ? R.min(window.innerWidth, window.innerHeight)
    : R.max(window.innerWidth, window.innerHeight)

  const outerWidth = isLandscape
    ? R.max(window.outerWidth, window.outerHeight)
    : R.min(window.outerWidth, window.outerHeight)

  const outerHeight = isLandscape
    ? R.min(window.outerWidth, window.outerHeight)
    : R.max(window.outerWidth, window.outerHeight)

  const widthToUseForOffset = isStandalone()
    ? outerWidth
    : innerWidth

  const heightToUseForOffset = isStandalone()
    ? outerHeight
    : innerHeight

  const topOffset = Math.round((heightToUseForOffset - screenHeight) / 2)
  const leftOffset = Math.round((widthToUseForOffset - screenWidth) / 2)

  return {
    width: screenWidth,
    height: screenHeight,
    top: topOffset,
    left: leftOffset,
    orientation: isLandscape ? 'landscape' : 'portrait'
  }
}

/**
 * This component will render a container that is the size of the user's screen
 * and then center that container within the browser's viewport. It will adjust
 * its top and left offsets to keep itself centered if the viewport size
 * changes.
 *
 * This has the following trade-offs:
 * - The container will never change size when the window/viewport is resized.
 * - If the container has children that set their width or height properties to
 *   100%, some of their content may wind up off-screen.
 */
export function CenteredScreenPortal(props: React.HTMLProps<HTMLDivElement>) {
  const [
    { width, height, top, left },
    setDimensions
  ] = React.useState(computePortalDimensions())

  /**
   * [Effect]
   *
   * In order to keep the container centered in the viewport, re-calculate the
   * top and left offset to apply to the container when the window is resized.
   */
  React.useEffect(() => {
    const onResize = () => setDimensions(computePortalDimensions())
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [])

  return (
    <div
      className={twMerge('fixed', props.className)}
      style={{
        // backgroundImage: debugBackgroundImage,
        width,
        height,
        top,
        left,
        ...props.style
      }}
      data-testid="centered-screen-portal"
    >
      {props.children}
    </div>
  )
}