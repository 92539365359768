import { Chalk, type ChalkInstance } from 'chalk'
import {
  createConsola,
  LogLevels,
  type ConsolaOptions,
  type ConsolaInstance
} from 'consola'

import globalStore from 'lib/storage'

/**
 * Union type of valid log level strings.
 */
export type LogLevel = keyof typeof LogLevels;

/**
 * @private
 *
 * Scope store for the logger.
 */
const store = globalStore.createInstance({ storeName: 'log' })

/**
 * @private
 *
 * Icons for our various log levels.
 */
// const icons: Partial<Record<LogLevel, {square: string; circle: string }>> = {
//   verbose: {
//     square: '🟪',
//     circle: '🟣'
//   },
//   debug: {
//     square: '⬜️',
//     circle: '⚪️'
//   },
//   info: {
//     square: '🟦',
//     circle: '🔵'
//   },
//   success: {
//     square: '🟩',
//     circle: '🟢'
//   },
//   // danger: {
//   //   square: '🟧',
//   //   circle: '🟠'
//   // },
//   fail: {
//     square: '🟧',
//     circle: '🟠'
//   },
//   ready: {
//     square: '🟩',
//     circle: '🟢'
//   },
//   start: {
//     square: '🟦',
//     circle: '🔵'
//   },
//   warn: {
//     square: '🟨',
//     circle: '🟡'
//   },
//   error: {
//     square: '🟥',
//     circle: '🔴'
//   }
// };

export interface EnhancedConsolaOptions extends ConsolaOptions {
  /** Provide an optional prefix that will appear before log messages. */
  prefix?: string
}

export interface EnhancedConsola extends ConsolaInstance {
  chalk: ChalkInstance
  prefix: <T = any>(arg: T) => T
}

/**
 * Creates and returns an `EnhancedConsola` instance.
 */
export function createLogger(options: Partial<EnhancedConsolaOptions> = {}): EnhancedConsola {
  const enhancedConsola = Object.assign(createConsola({
    level: 0,
    ...options
  }), {
    chalk: new Chalk({ level: 3 }),
    prefix: (arg: any) => arg
  })

  // Decorate log methods.
  Object.keys(LogLevels).forEach(logLevel => {
    const originalMethod = Reflect.get(enhancedConsola, logLevel)
    if (typeof originalMethod !== 'function') return

    Reflect.set(enhancedConsola, logLevel, (...args: Array<any>) => {
      // Add the configured prefix to the beginning of the arguments list.
      if (options.prefix) args.unshift(options.prefix)

      // Add an icon (if applicable) to the beginning of the arguments list.
      // const icon: IconDescriptor = Reflect.get(icons, logLevel);
      // if (icon) args.unshift(icon.square);

      // Invoke the original method.
      return Reflect.apply(originalMethod, enhancedConsola, args)
    })
  })

  // Pause logging while we determine if there is a valid log level in storage.
  enhancedConsola.pauseLogs()

  void store.getItem<keyof typeof LogLevels>('level').then(fromStorage => {
    // User provided an explicit log level, ignore any value from storage.
    if (options.level) return

    if (typeof fromStorage === 'string' && Reflect.has(LogLevels, fromStorage)) {
      // If storage has a valid log level, set it.
      enhancedConsola.level = LogLevels[fromStorage]
    }
  }).finally(() => {
    // Resume logging, which will flush all enqueued messages to the configured
    // reporter.
    enhancedConsola.resumeLogs()
  })

  return enhancedConsola
}

/**
 * Default logger instance.
 */
const defaultLogger = createLogger()

// defaultLogger.error('This is an ERROR message.');
// defaultLogger.warn('This is a WARN message.');
// defaultLogger.log('This is a LOG message.');
// defaultLogger.info('This is an INFO message.');
// defaultLogger.success('This is a SUCCESS message');
// defaultLogger.fail('This is a FAIL message');
// defaultLogger.ready('This is a READY message');
// defaultLogger.start('This is a START message');
// defaultLogger.debug('This is a DEBUG message.');
// defaultLogger.trace('This is a TRACE message.');

export default defaultLogger